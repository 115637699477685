<template>
    <div class="documentatBlock">
        <el-row :gutter="20">
            <el-col :span="10">
                <div class="TitleBocks">
                    <div>Формы Документов</div>
                    <span>Конструктор форм ввода данных</span>
                </div>
            </el-col>
            <el-col :span="2" class="text-right">
                <router-link class="el-button max_buttom el-button--default"  :to="{name: 'createForm'}">Создать</router-link>
            </el-col>
            <el-col :span="12">
                <el-input
                    placeholder="Type something"
                    prefix-icon="el-icon-search"
                    v-model="serchoInput">
                </el-input>
            </el-col>
        </el-row>
        <div class="documents_block">
            <div class="document_title">Выберите шаблон для редактирования</div>
            <div class="item-documents">ЭМК Амбулаторная карта пациента</div>
            <div class="item-documents">ЭМК Амбулаторная карта пациента</div>
            <div class="item-documents">ЭМК Амбулаторная карта пациента</div>
            <div class="item-documents">ЭМК Амбулаторная карта пациента</div>
        </div>
        <el-pagination
            class="mt-3 mypagination"
            background
            layout="prev, pager, next"
            :total="1000">
        </el-pagination>
    </div>
</template>
<script>
  export default {
    name: 'createForm',
    data() {
        return {
           serchoInput: '',
        }
    },
    
  }
</script>
<style lang="scss">
.documentatBlock .max_buttom:hover{
    text-decoration: none;
}
.TitleBocks{
    font-size: 18px;
    line-height: 20px;
    span{
        font-size: 12px;
        color: #8e8e8e;
    }
}
.documents_block{
    background-color: #fff;
    margin-top: 20px;
    border-radius: 4px;
    overflow: hidden;

    div{padding: 15px 20px;}
    .document_title{
        background-color: #cecece6b;
    }
    .item-documents{
        border-top: 1px solid #f2f2f2;
        transition: background-color .5s;
        cursor: pointer;
        &:hover{
            background-color: #cecece6b;
            
        }
    }
}
</style>